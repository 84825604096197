
import { appModes } from "@/appModes";
import appSetupFactory from "@/Study/main";

const firebaseConfig = {
    apiKey: "AIzaSyCsMbvj72KqDDmnawvncWsrXJuj_d2X_70",
    authDomain: "vaccine-questionnaire.firebaseapp.com",
    projectId: "vaccine-questionnaire",
    storageBucket: "vaccine-questionnaire.appspot.com",
    messagingSenderId: "601467191266",
    appId: "1:601467191266:web:3aa225fc40248ce536c3bb"
};

const appSetup = appSetupFactory(firebaseConfig, appModes.VACCINE_QUESTIONNAIRE, false);
export default appSetup;